import React, { useMemo } from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, FreeMode } from "swiper";
import { StaticImage } from "gatsby-plugin-image"

import 'swiper/css';
import "swiper/css/navigation";

import Contact from "../../components/sections/contact";

const CoffeeBean = () => {

    const imageProps = {
        layout: "fullWidth",
        quality: 45,
        formats: ["auto", "webp", "avif"],
        placeholder: "blurred",
        className: "h-full"
    }

    // const [modal, setModal] = useState(0)
    // const [caption, setCaption] = useState("")

    const InteriorImage = ({index, item}) => {
        switch(index){
            case 1:
                return <StaticImage src={`../../images/brand/coffee-bean/entrance.png`} alt={item} {...imageProps}/>
            case 2:
                return <StaticImage src={`../../images/brand/coffee-bean/inside-1.jpg`} alt={item} {...imageProps}/>
            case 3:
                return <StaticImage src={`../../images/brand/coffee-bean/coffee-bean-3.jpg`} alt={item} {...imageProps}/>
            default:
                return <StaticImage src={`../../images/brand/coffee-bean/inside-3.jpeg`} alt={item} {...imageProps}/>
        }
    }

    const sliderArray = useMemo(()=> [
        "CoffeeBean Central Gurney",
        "CoffeeBean Central Gurney",
        "CoffeeBean Central Gurney",
        "CoffeeBean Central Gurney",
    ], [])

    return(
        <Layout>
        <Seo title="Central@Gurney | Coffee Bean" />

        {
            typeof window !== 'undefined' && 
            <div>
                <div className="container mx-auto px-5">
                    <div className="pt-24 pb-6 flex flex-col items-center justify-center">
                        {/* <div className="w-20 mr-5">
                            <StaticImage src="../../images/brand/brand-2.png" className="transition-basic hover:opacity-50" {...imageProps}></StaticImage>
                        </div> */}
                        <h2 className="text-4xl font-semibold mb-2">Coffee Bean</h2>
                        <p className="font-light text-gray-600">7.30am – 11pm daily</p>
                        <p className="font-light text-gray-600">For enquiries: 04-295 8972</p>
                    </div>
                </div>
                <Swiper
                    className="swiper-tenant"
                    modules={[Navigation, Autoplay, FreeMode]}
                    navigation={true}
                    spaceBetween={8}
                    slidesPerView={1}
                    // centeredSlides
                    breakpoints={{
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 16,
                            // centeredSlides: false,
                            initialSlide: 0
                        },
                    }}
                >
                    {sliderArray.map((item, i) => (
                        <SwiperSlide key={"tenant"+item}>
                            <div className="aspect-video bg-gray-200 relative md:my-5">
                                <InteriorImage index={i+1} item={item}/>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="container mx-auto px-5">
                    <div className="pt-10 pb-20 xl:px-16 w-full mx-auto md:w-7/12">
                        <p className="font-light leading-relaxed">         
    The Coffee Bean & Tea Leaf® brand is a leading global roaster and retailer of specialty coffees and teas and is widely credited for driving high quality and innovation to the coffee and tea industry. The company sources the finest ingredients and flavours from around the world and hand blends coffee and tea for the freshest flavours. The Coffee Bean & Tea Leaf® brand started the frozen coffee drink craze with the invention of  The Original Ice Blended®  drink and is also the first global coffee and tea retailer to offer cold brew tea. The company currently has more than 1,200 retail locations across the globe and can be found in grocery aisles as well as specialty locations including airports and hotels. 
                        </p>
                        <div className="w-full mx-auto relative mt-12">
                            <StaticImage src={`../../images/promotion/promotion-6-coffebean.jpg`} {...imageProps}/>
                        </div>
                    </div>
                </div>
                <Contact/>
            </div>
        }

        </Layout>
    )
}

export default CoffeeBean
